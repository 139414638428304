export const getRandomCode = (length = 6) => {
    const numbers = '0123456789';
    const lowerLetters = 'abcdefghijklmnopqrstuvwxyz';
    const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const allChars = numbers + lowerLetters + upperLetters;

    let result = '';
    let hasNumber = false;
    let hasLetter = false;

    // Ensure at least one number and one letter are included.
    result += numbers[Math.floor(Math.random() * numbers.length)];
    hasNumber = true;
    result += lowerLetters[Math.floor(Math.random() * lowerLetters.length)] || upperLetters[Math.floor(Math.random() * upperLetters.length)];
    hasLetter = true;

    // Fill the rest of the string length with random characters from allChars.
    for (let i = 2; i < length; i++) {
        result += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the result to ensure randomness in position of numbers and letters.
    result = result.split('').sort(() => Math.random() - 0.5).join('');

    return result;
}