<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 表单配置</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="btns"><span @click="$router.back()" class="btn-back">返回 ></span></div>
      <el-steps :active="activeIndex" finish-status="success" simple style="margin: 20px;background: #fff;">
        <el-step title="基本信息"></el-step>
        <el-step title="表单配置"></el-step>
      </el-steps>
      <div v-if="activeIndex==0" class="content c1">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="表单名称">
            <el-input v-model="form.Name"></el-input>
          </el-form-item>
          <el-form-item label="表单编号">
            <el-input v-model="form.Codes" :disabled="!!id"></el-input>
          </el-form-item>
          <el-form-item label="表单描述">
            <el-input type="textarea" v-model="form.Desc" :rows="5"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeIndex==1" class="content">
        <formGenerator ref="form" :formInfo="form"></formGenerator>
      </div>
      <div slot="footer" align="center">
        <el-button v-if="activeIndex==0" @click="next">下一步</el-button>
        <template v-if="activeIndex==1">
          <el-button @click="activeIndex--">上一步</el-button>
          <el-button @click="save" type="success">保存</el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import formGenerator from '@/components/formGenerator/views/index/Home'
import {getRandomCode} from "@/util";

export default {
  name: "edit",
  components: {formGenerator},
  data() {
    return {
      id: this.$route.query.id || '',
      sta: {
        show: false,
        title: '添加',
      },
      activeIndex: 0,
      form: {
        Name: '',
        Codes: getRandomCode(),
        Desc: '',
      },
      config: {},
      teacherList: [],
    }
  },
  mounted() {
    // this.getConfig()
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/flow-form/detail?id=" + this.id,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.form = AppendData
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    getConfig() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/flow-form/config",
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.config = AppendData
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    next() {
      if (!this.form.Name) {
        this.$message.warning('请输入标题')
        return
      }
      this.activeIndex++
      this.$nextTick(()=>{
        this.$refs.form.init(this.id)
      })
    },
    save() {
      let editorTxt = this.$refs.form.AssembleFormData()
      this.form.EditorTxt = JSON.stringify(editorTxt)
      console.log('editorTxt',editorTxt)
      if (this.id) {
        this.update()
      } else {
        this.add()
      }
    },
    update() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/flow-form/update",
        data: self.form,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    add() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/flow-form/add",
        data: self.form,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.wrap {
  background: #fff;
  margin: 0 20px;
  padding-bottom: 20px;
  box-sizing: border-box
}

.wrap .btns {
  padding: 10px 20px 0;
  text-align: right;
}

.wrap .btns .btn-back {
  cursor: pointer;
}

.content {
  min-height: 60vh;
}

.content.c1 {
  width: 50%;
  margin: 0 auto;
}

/*/deep/ .el-step__title.is-success{*/
/*  color: #409EFF;*/
/*}*/

/*/deep/ .el-step__head.is-success{*/
/*  color: #409EFF;*/
/*  border-color: #409EFF;*/
/*}*/
</style>